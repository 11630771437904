const ronaldoTeam = (y) => {
  if(y === "2002-2003" || y === "2002") {
    return "Sporting CP";
  } else if(y === "2003") {
    return "Man Utd/Sporting"
  } else if(y === "2018-2019" || y === "2019-2020" || y === "2020-2021" || y === "2019" || y === "2020") {
    return "Juventus";
  } else if(y === "2003-2004" || y === "2004-2005" || y === "2005-2006" || y === "2006-2007" || y === "2007-2008" || y === "2008-2009" || y === "2004" || y === "2005" || y === "2006" || y === "2007" || y === "2008" || y === "2021-2022" || y === "2022") {
    return "Man United";
  } else if(y === "2009") {
    return "Man Utd/Real"
  } else if(y === "2022-2023") {
    return "Man Utd/Al Nassr"
  } else if(y === "2009-2010" || y === "2010-2011" || y === "2011-2012" || y === "2012-2013" || y === "2013-2014" || y === "2014-2015" || y === "2015-2016" || y === "2016-2017" || y === "2017-2018" || y === "2010" || y === "2011" || y === "2012" || y === "2013" || y === "2014" || y === "2015" || y === "2016" || y === "2017") {
    return "Real Madrid";
  } else if(y === "2018") {
    return "Real/Juve"
  } else if(y === "2021") {
    return "Juve/Man Utd"
  } else {
    return "Al Nassr";
  }
}

export default ronaldoTeam