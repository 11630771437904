import React from "react"
import Icon from "../Utilities/Icon"
import largeNum from "../../js/largeNum"
import validNum from "../../js/validNum"
import styles from "./StatsBlock.module.css"

const StatsBlock = (props) =>(
  <div className={`${styles.statsBlock} ${props.player === 'Messi' ? styles.MessiStatsBlock : styles.RonaldoStatsBlock} relative text-center w-1/2`}>
    <h3 className={`${styles.playerTitle}`}>
      <span className={`${styles.player}`}>{props.player} </span>
      <span className="sr-only">Stats {props.competition} {props.year} </span>
      <span className={`${styles.club}`}>{props.team}</span>
    </h3>
    <ul className={`${styles.mainStats} relative flex`}>
      <li className={`${styles.stat} ${styles.goals}`}>
        <span className={`${styles.statNum} ${largeNum(props.goals) ? styles.largeNum : ''}`}>{props.goals}</span>
        <span className={`${styles.statType}`}> Goals</span>
      </li>
      <li className={`${styles.stat} ${styles.assists}`}>
        <span className={`${styles.statNum} ${largeNum(props.assists) ? styles.largeNum : ''}`}>{props.assists ? props.assists : '-'}</span>
        <span className={`${styles.statType}`}> Assists</span>
      </li>
      <li className={`${styles.stat} ${styles.apps}`}>
        <span className={`${styles.statNum} ${largeNum(props.apps) ? styles.largeNum : ''}`}>{props.apps}</span>
        <span className={`${styles.statType}`}> Apps</span>
      </li>
    </ul>
    <ul className={`${styles.subStats}`}>
      <li className={`${styles.subStatsItem}`}>
        <span className={`${styles.subStat} ${props.minsPerGoalResult === 'w' && props.minsPerGoalCont !== 0 ? 'text-green-400' : ''}`}>
          {props.minsPerGoalResult === 'w' && props.minsPerGoalCont !== 0 ? <Icon iconName="icon-star" /> : ''}
          {props.minsPerGoal ? props.minsPerGoal : props.goalsPerGame ? parseFloat(props.goalsPerGame).toFixed(2) : '0'}
        </span>
        <span className={`${styles.subStatType} ${props.minsPerGoalResult === 'w' && props.minsPerGoalCont !== 0 ? 'text-green-400' : ''}`}> {props.minsPerGoal ? 'mins per goal' : 'goals per game'}</span>
      </li>
      {props.minsPerGoalCont ?
        <li className={`${styles.subStatsItem}`}>
          <span className={`${styles.subStat} ${props.minsPerGoalContributionResult === 'w' && props.minsPerGoalCont !== 0 ? 'text-green-400' : ''}`}>
            {props.minsPerGoalContributionResult === 'w' && props.minsPerGoalCont !== 0 ? <Icon iconName="icon-star" /> : ''}
            {props.minsPerGoalCont}
          </span>
          <span className={`${styles.subStatType} ${props.minsPerGoalContributionResult === 'w' && props.minsPerGoalCont !== 0 ? 'text-green-400' : ''}`}> mins per goal contribution</span>
        </li>
      : ''}
    </ul>
  </div>
)

export default StatsBlock