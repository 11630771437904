import React from "react"
import Icon from "./Icon"
import styles from "./Modal.module.css"


class Modal extends React.Component {

  render() {

    return (

      <article className={`${styles.breakdown} fixed inset-0 bg-white ${this.props.state ? `${styles.breakdownActive}` : ''}`}>

        <div className={`${styles.header} pt-3 lg:pt-5 relative`}>
          <h2 className={`${styles.title} px-4 leading-tight`}>
            <span className={`block uppercase font-light text-xs text-gray-500`}>{this.props.subTitleTop} </span>
            <span className={`block uppercase font-bold text-2xl my-1 tracking-wide`}>{this.props.title} </span>
            <span className={`block font-semibold text-sm`}>{this.props.subTitle} </span>
          </h2>
          <p className={`flex mt-4`}>
            <span className={`block p-1 w-1/2 text-sm bg-messi text-white lg:absolute lg:w-auto lg:bg-transparent lg:text-lg lg:font-semibold lg:text-messi`}>Lionel Messi </span>
            <span className={`block p-1 w-1/2 text-sm bg-ronaldo text-white lg:absolute lg:w-auto lg:bg-transparent lg:text-lg lg:font-semibold lg:text-ronaldo`}>Cristiano Ronaldo</span>
          </p>
        </div>

        <div className={`${styles.body} h-screen pt-8 lg:pt-12 pb-56 overflow-y-scroll scrolling-touch`}>

          {this.props.children}
          
        </div>

        <button
          id={`CloseModal-${this.props.title.replace(/ /g, '-')}`}
          aria-label="Close"
          onClick={this.props.handler}
          className={`fixed right-0 bottom-0 bg-highlight py-4 px-6 md:bottom-auto md:top-0 z-50 focus:outline-none focus:bg-highlight-lighten10 text-noir`}
        >
          <Icon iconName="icon-times" />
        </button>
      </article>

    )
  }
}

export default Modal