import React from "react"
import ronaldoTeam from "../../js/ronaldoTeam"
import messiTeam from "../../js/messiTeam"
import statWinner from "../../js/statWinner"

import StatsBlock from "./StatsBlock"
import Modal from "../Utilities/Modal"
import ModalDetailedStats from "./ModalDetailedStats"
import ButtonWithIcon from "../Utilities/ButtonWithIcon"
import styles from "./CompetitionBlock.module.css"


class CompetitionBlock extends React.Component {

  constructor(props) {
    super(props)
    this.toggleDetailedModal = this.toggleDetailedModal.bind(this)
    this.hideDetailedModal = this.hideDetailedModal.bind(this)
  }

  state = {
    detailedModalActive: false
  };

  toggleDetailedModal = () => {
    this.setState(state => ({ detailedModalActive: !state.detailedModalActive }));
    document.body.classList.add('overflow-hidden');
  };
  hideDetailedModal() {
    this.setState(({ detailedModalActive: false }));
    document.body.classList.remove('overflow-hidden');
  }

  render() {

    const mdata = this.props.mdata.filter(d => d.node.competition === this.props.competition)
    const rdata = this.props.rdata.filter(d => d.node.competition === this.props.competition)

    // SIMPLIFY ARRAYS
    let mStats
    let rStats

    Object.fe = arr => Object.assign({}, ...Array.from(arr, ([k, v]) => ({[k]: v}) ));

    mStats = mdata.map(entry => {
      return Object.fe(
        Object.entries(entry.node).map(([key, value]) =>
          [`m${key}`, value]
        )
      )
    })

    rStats = rdata.map(entry => {
      return Object.fe(
        Object.entries(entry.node).map(([key, value]) =>
          [`r${key}`, value]
        )
      )
    })

    // // Combine the two arrays so we have both players' stats in a single object per year/season
    // const bothStats = mStats.map((item, key) => Object.assign({}, item, rStats[key]))

    const [mminsPerGoal] = mdata.map(d => d.node.minsPerGoal)
    const [rminsPerGoal] = rdata.map(d => d.node.minsPerGoal)
    const [mminsPerGoalContribution] = mdata.map(d => d.node.minsPerGoalContribution)
    const [rminsPerGoalContribution] = rdata.map(d => d.node.minsPerGoalContribution)


    const year = rdata.map(d => d.node.year)[0]


    // Competition Block Title
    const competitionBlockTitle =
      this.props.compTitle ? this.props.compTitle
      : this.props.competition


    return (

      <article className={`${styles.competitionBlock} competition-block text-center`}>

        <div className={`${styles.container} flex flex-wrap mx-auto`}>

          <h2 className="w-full uppercase tracking-wider font-bold text-lg lg:text-xl mb-3 xs:mb-4 md:mb-5">
            {competitionBlockTitle} {this.props.subtitle ? ( <small className={`${styles.subtitle} block normal-case text-gray-600 font-normal w-full mx-auto mt-1`}>{this.props.subtitle}</small> ) : ''}
          </h2>

          { mdata.map(d => (
              <StatsBlock
                key={d.node.competition}
                player="Messi"
                team={this.props.team ? this.props.team : this.props.type === "int" ? "Argentina" : this.props.type === "inspain" || this.props.competition === "El Clasico" ? "Barcelona" : this.props.type === "club" ? messiTeam(d.node.year) : ''}
                competition={d.node.competition}
                year={d.node.year ? d.node.year : this.props.type === "inspain" ? '2009/10 - 2017/18' : 'All Time'}
                goals={d.node.goals}
                assists={d.node.assists}
                apps={d.node.apps}
                minsPerGoal={Math.round(d.node.minsPerGoal)}
                minsPerGoalResult={statWinner(mminsPerGoal, rminsPerGoal, 'lowWins') === 'm' ? 'w' : 'l'}
                minsPerGoalCont={Math.round(d.node.minsPerGoalContribution)}
                minsPerGoalContributionResult={statWinner(mminsPerGoalContribution, rminsPerGoalContribution, 'lowWins') === 'm' ? 'w' : 'l'}
                goalsPerGame={d.node.goalsPerGame}
              />
            ))
          }

          { rdata.map(d => (
              <StatsBlock
                key={d.node.competition}
                player="Ronaldo"
                team={this.props.team ? this.props.team : this.props.type === "int" ? "Portugal" : this.props.type === "inspain" || this.props.competition === "El Clasico" ? "Real Madrid" : this.props.type === "club" ? ronaldoTeam(d.node.year) : ''}
                competition={d.node.competition}
                year={d.node.year ? d.node.year : this.props.type === "inspain" ? '2009/10 - 2017/18' : 'All Time'}
                goals={d.node.goals}
                assists={d.node.assists}
                apps={d.node.apps}
                minsPerGoal={Math.round(d.node.minsPerGoal)}
                minsPerGoalResult={statWinner(mminsPerGoal, rminsPerGoal, 'lowWins') === 'r' ? 'w' : 'l'}
                minsPerGoalCont={Math.round(d.node.minsPerGoalContribution)}
                minsPerGoalContributionResult={statWinner(mminsPerGoalContribution, rminsPerGoalContribution, 'lowWins') === 'r' ? 'w' : 'l'}
                goalsPerGame={d.node.goalsPerGame}
              />
            ))
          }

          {this.props.smallPrint ? (
            <p className="text-xs text-gray-600 text-center mt-3 px-2 xs:px-0 w-full">{this.props.smallPrint}</p>
          ) : ''}

          {this.props.modal !== false ?
            <ButtonWithIcon
              id={`${this.props.competition.replace(/\s+/g, '')}DetailedStats`}
              ariaLabel={`${this.props.competition} Detailed Stats`}
              handler={this.toggleDetailedModal}
              text="Detailed Stats"
              icon="icon-plus"
              padding="py-1 pl-3 pr-2 md:py-2 md:pl-4 md:pr-3"
            />
          : '' }

          {this.props.modal !== false ?
            <Modal
              handler={this.hideDetailedModal}
              state={this.state.detailedModalActive}
              key={`ds${rdata.map(d => d.node.competition)}`}
              type={this.props.type}
              title={this.props.modalTitle ? this.props.modalTitle : competitionBlockTitle}
              subTitle={this.props.modalSubTitle ? this.props.modalSubTitle : year ? year : this.props.type === "inspain" ? "2009/10 - 2017/18" : "All Time Stats"}
              subTitleTop="messivsronaldo.app"
            >
              <ModalDetailedStats
                mdata={mStats}
                rdata={rStats}
                competition={this.props.competition}
                perfmsg={this.props.perfmsg}
                showPerfStats={this.props.showPerfStats}
              />
            </Modal>
          : '' }

        </div>
      </article>

    )
  }
}

export default CompetitionBlock