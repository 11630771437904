import React from "react"
//import { Link } from "gatsby"

import Icon from "./Icon"
import styles from "./ButtonWithIcon.module.css"


class ButtonWithIcon extends React.Component {

  render() {

    return (

      <button
        id={this.props.id}
        aria-label={this.props.ariaLabel}
        disabled={this.props.disabled ?? false}
        onClick={this.props.handler}
        className={`${styles.buttonWithIcon} bg-highlight hover:bg-highlight-lighten10 transition-300 ${this.props.padding} ${this.props.margins} mx-auto text-xs sm:text-sm text-noir rounded-full uppercase font-semibold flex items-center`}>
          {this.props.text} <Icon iconName={this.props.icon} class="ml-2 bg-noir rounded-full p-1 text-highlight" />
      </button>

    )
  }
}

ButtonWithIcon.defaultProps = {
  margins: "mt-4 xs:mt-6",
  padding: "py-2 pl-4 pr-3",
};

export default ButtonWithIcon