let largeNum = (x) => {
  const y = parseInt(x)
  if(y > 99) {
    largeNum = true
  } else {
    largeNum = false
  }
  return largeNum
}

export default largeNum