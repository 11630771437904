import React from "react"
import validNum from "../../js/validNum"
import Icon from "../Utilities/Icon"
import styles from "./ModalDetailedStats.module.css"


class ModalStat extends React.Component {

  render() {

    const mstat = (this.props.mstat && validNum(this.props.mstat)) ? this.props.mstat : ''
    const rstat = (this.props.rstat && validNum(this.props.rstat)) ? this.props.rstat : ''

    return (

      <>

      {(mstat || rstat) ? (

        <li className={`w-1/2 sm:w-1/3 md:w-1/4 flex flex-wrap content-start p-1 xs:p-2`}>

          <h4 className={`w-full bg-gray-300 text-gray-700 font-semibold text-xs p-1 uppercase tracking-wider border border-gray-100`}>
            {this.props.statType}
          </h4>

          {(mstat && validNum(mstat)) ? (
            <div className={`${styles.stat}`}>
              <span className={`${styles.statPlayerText}`}>Messi </span>
              <span className={`${styles.value}`}>
                {mstat}
                {this.props.msubStat && this.props.statType === "Penalties" ? (
                  <small className="text-sm">/{Number(this.props.msubStat) + Number(mstat)}</small>
                ) : ''}
                {this.props.msubStat && this.props.statType === "Expected Goals (xG)" ? (
                  <small className={`${Number(this.props.msubStat) < 0 ? 'text-green-200' : 'text-red-200'} text-sm block`}>({this.props.msubStat > 0 ? '+' : ''}{this.props.msubStat})</small>
                ) : ''}
                {this.props.msubStat && this.props.statType === "Expected Assists (xA)" ? (
                  <small className={`${Number(this.props.msubStat) > 0 ? 'text-green-200' : 'text-red-200'} text-sm block`}>({this.props.msubStat > 0 ? '+' : ''}{this.props.msubStat})</small>
                ) : ''}
                {this.props.statWinner === "m" ? <Icon iconName="icon-star" /> : ''}
              </span>
              <span className="sr-only"> {this.props.statType}</span>
            </div>
          ) : ''}

          {(rstat && validNum(rstat)) ? (
            <div className={`${styles.stat} ${styles.statRonaldo}`}>
              <span className={`${styles.statPlayerText}`}>Ronaldo </span>
              <span className={`${styles.value}`}>
                {rstat}
                {this.props.rsubStat && this.props.statType === "Penalties" ? (
                  <small className="text-sm">/{Number(this.props.rsubStat) + Number(rstat)}</small>
                ) : ''}
                {this.props.rsubStat && this.props.statType === "Expected Goals (xG)" ? (
                  <small className={`${Number(this.props.rsubStat) < 0 ? 'text-green-200' : 'text-red-200'} text-sm block`}>({this.props.rsubStat > 0 ? '+' : ''}{this.props.rsubStat})</small>
                ) : ''}
                {this.props.rsubStat && this.props.statType === "Expected Assists (xA)" ? (
                  <small className={`${Number(this.props.rsubStat) > 0 ? 'text-green-200' : 'text-red-200'} text-sm block`}>({this.props.rsubStat > 0 ? '+' : ''}{this.props.rsubStat})</small>
                ) : ''}
                {this.props.statWinner === "r" ? <Icon iconName="icon-star" /> : ''}
              </span>
              <span className="sr-only"> {this.props.statType}</span>
            </div>
          ) : ''}

          {this.props.msg ? (
            <div className="w-full text-center text-xs text-gray-500 leading-tight">{this.props.msg}</div>
          ) : ''}

        </li>

      ) : ''}

      </>

    )
  }
}

export default ModalStat